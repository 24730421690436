<template>
  <article>
    <div class="flex items-center flex-wrap mb-10 mr-3">
      <h2 class="text-2xl font-bold mr-3">Bolt Rides</h2>
    </div>

    <section class="border border-blue-200">
      <datatable
        :url="users.url"
        :ajax="true"
        :ajaxPagination="true"
        :columns="users.columns"
        :filters="users.filters"
        :query="users.query"
        :fillable="true"
        :dropdown="false"
        :on-click="click"
        sort="created_at"
        order="desc"
        ref="table"
        @selection-update="(data) => selectionUpdate(data, form)"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-5"
              v-model="users.query"
              @submit="reloadTable"
            />
            <div class="flex items-start flex-wrap xl:flex-no-wrap">
              <div class="flex flex-wrap items-center w-full">
                <span class="processor-filter-title">Filters:</span>
                <template v-for="(filter, index) in users.filters">
                  <span
                    v-html="filter.title"
                    :key="index"
                    class="processor-filter-item"
                    :class="{ active: currentFilter === filter }"
                    @click.prevent="toggleFilter(filter)"
                  />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:td-7="{ item: { row } }">
          <div class="badge" :class="[row.bolt_user_status === 'deactivated' ? 'badge-red' : 'badge-green']">
            <ion-icon v-if="row.bolt_user_status === 'deactivated'" name="close" />
            <ion-icon v-else name="checkmark" />
          </div>
        </template>
      </datatable>
    </section>
    <modals-user
      :allow-delete="false"
      :selected-user="users.selected"
      ref="userModal"
      @close="userModalClosed"
    />
  </article>
</template>

<script>
export default {
  name: 'BoltRides',
  data() {
    return {
      users: this.$options.resource([], {
        selected: null,
        query: '',
        url: `${this.$baseurl}/admin/personal/bolt/rides`,
        columns: [
          {
            name: 'user_id',
            th: 'User ID',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (row, amount) => '₦' + this.$options.filters.currency(amount)
          },
          {
            name: 'name',
            th: 'Name',
            render: ({ user }) => user.name + ' ' + user.last_name,
            sortable: false,
          },
          {
            name: 'user.email',
            th: 'Email Address',
            render: ({ user }) => user.email,
            sortable: false,
          },
          {
            name: 'bolt_credit_limit',
            th: 'Bolt Limit',
            render: ({ user }) => {
              return this.$options.filters.currency(user.profile.bolt_credit_used)
                + ' / '
                + this.$options.filters.currency(user.profile.bolt_credit_limit);
            },
            sortable: false
          },
          {
            name: 'description',
            th: 'Description',
            render: (row, description) => this.$options.filters.truncate(description, 30),
            sortable: false,
          },
          {
            name: 'bolt_user_status',
            th: 'Bolt Status',
            sortable: false
          },
          {
            name: 'date',
            th: 'Date',
            render: (user, date) => this.$options.filters.dateFormat(date),
          },
        ],
        filters: [],
      }),
    };
  },
  methods: {
    click({ user }) {
      this.users.selected = user;
      this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    userModalClosed() {
      this.users.selected = null;
    },
  },
};
</script>
